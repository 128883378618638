import axios from 'axios';
import * as config from './config';
import { Base64 } from 'js-base64';
import Helper from './helper';

async function apicall_upload_G2(method, url, data) {
  let token = Helper.getParamUrlByKey('token');
  const res = await axios({
    method: method,
    url: `${config.server}${url}`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    }
  });
  return res;
}


export default {
  apicall_upload_G2
};