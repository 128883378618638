import React, { useContext } from "react";
import { Modal } from "antd";
import HuongDan from "./../images/hdchup.svg";
import { Animated } from "react-animated-css";

const MDUserManual = (props) => {
  return (
    <div style={{ fontSize: 14 }}>
      <Animated
        animationIn="slideInUp"
        animationInDuration={2000}
        isVisible={true}
      >
        <Modal
          title="Hướng dẫn chụp ảnh xe"
          className="custom-modal-antd-camera"
          style={{
            maxWidth: "100%",
            margin: 0,
            verticalAlign: "bottom",
            padding: 0,
            maxHeight: "90%",
          }}
          centered={true}
          footer={false}
          closable={true}
          visible={props.isModalVisible}
          onCancel={props.handleCancel}
          maskStyle={{ background: "#000000", opacity: 0.8 }}
        >
          <img src={HuongDan} style={{ width: "100%" }} />
        </Modal>
      </Animated>
    </div>
  );
};

export default MDUserManual;
