import React, { useState, useRef, useCallback, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message} from "antd";
import "antd/dist/antd.css";
import FrontLeft from "./frontLeft";
import FrontRight from "./frontRight";
import BackRight from "./backRight";
import BackLeft from "./backLeft";
import InspectionStamps from "./inspectionStamps";
import Speedometer from "./Speedometer";
import ViewAndComplete from "./viewAndComplete";
import Helper from "./../../extra/helper";


import MDHuongdan from "./modal/mdUserManual";
import Service from "./../../extra/getAPI";
import "./css/style.css";
export const AuthContext = React.createContext();
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 25,
      color: "#009B4B",
    }}
    spin
  />
);

const Index = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [listImg, setListIMG] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const PageView = () => {
    switch (step) {
      case 1:
        return <FrontLeft />;
      case 2:
        return <FrontRight />;
      case 3:
        return <BackRight />;
      case 4:
        return <BackLeft />;
      case 5:
        return <InspectionStamps />;
      case 6:
        return <Speedometer />;
      case 7:
        return <ViewAndComplete />;
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    console.log(listImg);
  }, [listImg]);

  const setListImgG2 = (linkG2, keyName) => {
    setListIMG({ ...listImg, [keyName]: linkG2 });
  };

  // Bắt đầu nén hình
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const resizeMe = (img) => {
    var canvas = document.createElement("canvas");
    var width = img.width;
    var height = img.height;
    const max_width = 1200;
    const max_height = 1200;
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }

    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    // Compress jpg, 70%
    return canvas.toDataURL("image/jpeg", 0.7);
  };

  const compressIMG = (file, name, keyName) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      var blob = new Blob([event.target.result]);
      window.URL = window.URL || window.webkitURL;
      var blobURL = window.URL.createObjectURL(blob);
      const image = new Image();
      image.src = blobURL;
      image.onload = () => {
        const resized = resizeMe(image);
        // Download file test
        // const imageData = resized.replace("image/png", "image/octet-stream");
        // var link = document.createElement('a');
        // link.setAttribute('download', 'ResizeImage.jpg');
        // link.setAttribute('href', imageData);
        // link.click();
        // getValueImg(DataURIToBlob(resized));
        // console.log(DataURIToBlob(resized));
        uploadG2(DataURIToBlob(resized), name, keyName);
      };
    };
  };

  // Kết thúc nén hình

  const uploadG2 = (file, name, keyName) => {
    setLoading(true);
    const random = (Math.random() + 1).toString(36).substring(7);
    const carName = Helper.getParamUrlByKey('carName')?Helper.getParamUrlByKey('carName') : random;
    var form = new FormData();
    const timestamp = +new Date();
    form.append("storage", "s3");
    form.append("file", file);
    form.append("path", `/user/${name}/${carName}/${timestamp}-${random}`);
    Service.apicall_upload_G2("POST", `upload`, form)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.complete) {
          if (step !== 7) {
            setStep(step + 1);
          }
          setListImgG2(data.link, keyName);
        }else{
          MessageErr('Có lỗi xảy ra. Vui lòng chụp lại ảnh!');
        }
      })
      .catch((err) => {
        setLoading(false);
        MessageErr('Có lỗi xảy ra. Vui lòng chụp lại ảnh!');
      });
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageErr = (content) => {
    message.error(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      30
    );
  };

  const postMessage = (listIMGG2) => {
    alert("vào postMessage");
    console.log('postMess',listIMGG2);
    window.parent.postMessage(
      {
        type: "sumary",
        message: listIMGG2,
      },
      "*"
    );
  };

  return (
    <AuthContext.Provider
      value={{
        compressIMG,
        setStep,
        listImg,
        postMessage
      }}
    >
      <MDHuongdan handleCancel={handleCancel} isModalVisible={isModalVisible} />
      <Spin
        indicator={antIcon}
        tip="Hình ảnh đang được xử lý. Vui lòng chờ trong giây lát!"
        wrapperClassName="spin-custom"
        spinning={loading}
      >
        {PageView()}
      </Spin>
    </AuthContext.Provider>
  );
};

export default Index;
