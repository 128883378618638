import React, { useContext } from "react";
import { Modal, Button} from "antd";
import HuongDan from "./../images/warning.svg";

const Cancel = (props) => {
  const css = {
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      height: "80px",
      alignItems: "center",
    },
    button: {
      width: "46%",
      height: 40,
      borderRadius: 7,
      background: "#009B4B",
      borderColor: "#009B4B",
      color: "#FFF",
      fontWeight: 600,
    }
  };
  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        // title="Hướng dẫn chụp ảnh xe"
        // className="custom-modal-antd-camera"
        style={{
          margin: 0,
          padding: 0,
        }}
        centered={true}
        footer={false}
        // closable={true}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        closable={false}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={{ textAlign:'center'}}>
          <img src={HuongDan} style={{ width: "65px" }} />
          <div style={{fontSize: 18, marginTop: 10, fontWeight: 600, color:'#383D3B'}}>
            Xác nhận huỷ
          </div>
          <div style={{fontSize: 16, marginTop: 10, color:'#515855'}}>
          Hình ảnh quý khách đã chụp sẽ không được lưu lại nếu xác nhận hủy
          </div>
          <div style={css.divButton}>
            <Button
              onClick={()=>props.handleCancel()}
              style={{
                ...css.button,
                background: "#fff",
                color: "#009B4B",
                borderColor: "#009B4B",
              }}
            >
              Đóng
            </Button>
            <Button style={css.button}>
              Xác nhận
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Cancel;
