// build dev
const server = 'https://g2-dev.globalcare.vn/';

//build beta
// const server = 'https://g2-beta.globalcare.vn/';


// build live
// const server = 'https://g2.globalcare.vn/';

export {
    server
};