import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import Camera from "./components/camera";
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route component={Camera} path="/camera" />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
